import React from 'react'

const NotFound = () => {
  return(
    <main style={{ minHeight: 800 }}>
      <div className="container">
        NOT FOUND
      </div>
    </main>
  )
}

export default NotFound
